// Store url strings and other constants that might be used
// across multiple files here

export const host = process.env.NEXT_PUBLIC_HOST;
export const paddle_token = process.env.NEXT_PUBLIC_PADDLE_CLIENT_TOKEN;
export const paddle_environment = process.env.NEXT_PUBLIC_PADDLE_ENVIRONMENT;
export const paddle_product_adv_id = process.env.NEXT_PUBLIC_PADDLE_PRODUCT_ADV_ID;
export const paddle_price_adv_annual_id = process.env.NEXT_PUBLIC_PADDLE_PRICE_ADV_ANNUAL_ID;
export const paddle_price_adv_monthly_id = process.env.NEXT_PUBLIC_PADDLE_PRICE_ADV_MONTHLY_ID;
export const paddle_product_ess_id = process.env.NEXT_PUBLIC_PADDLE_PRODUCT_ESS_ID;
export const paddle_price_ess_annual_id = process.env.NEXT_PUBLIC_PADDLE_PRICE_ESS_ANNUAL_ID;
export const paddle_price_ess_monthly_id = process.env.NEXT_PUBLIC_PADDLE_PRICE_ESS_MONTHLY_ID;

export const apiURL = 'user/';
export const stockAPIURL = 'markets/';
export const billingAPIURL = 'billing/';
export const rootURL = '/';

export const accountEndpoint = `${host}${apiURL}`;
export const stockEndpoint = `${host}${stockAPIURL}`;
export const billingEndpoint = `${host}${billingAPIURL}`;

export const profileURL = `${accountEndpoint}profile/`;
export const favoritesURL = `${profileURL}favorites/`;
export const registerURL = `${accountEndpoint}register/`;
export const registerVerifyURL = `${registerURL}verify/`;
export const loginURL = `${accountEndpoint}login/`;
export const logoutURL = `${accountEndpoint}logout/`;
export const logoutAllURL = `${accountEndpoint}lougout/all/`;
export const passwordURL = `${accountEndpoint}password/`;
export const passwordResetURL = `${passwordURL}reset/`;
export const passwordResetVerifyURL = `${passwordResetURL}verify/`;
export const passwordResetVerifiedURL = `${passwordResetURL}verified/`;
export const passwordChangeURL = `${passwordURL}change/`;
export const emailChangeURL = `${accountEndpoint}email/change/`;
export const emailChangeVerifyURL = `${emailChangeURL}verify/`;
export const userMeURL = `${accountEndpoint}me/`;
export const createCheckoutSessionURL = `${accountEndpoint}createCheckoutSession/`;
export const createCustomerPortalSessionURL = `${billingEndpoint}createCustomerPortalSession/`;
export const customerPortalURL = `${accountEndpoint}customer-portal/`;
export const contactUsURL = `${accountEndpoint}contactUs/`;
export const checkCouponURL = `${accountEndpoint}validateCoupon/`;
export const submitPaymentURL = `${accountEndpoint}submitPayment/`;
// export const createStripeUserURL = `${accountEndpoint}checkStripeUser/`;
// export const createStripeSubscriptionURL = `${accountEndpoint}createStripeSubscription/`;
// export const previewStripeSubscriptionURL = `${accountEndpoint}previewStripeSubscription/`;
// export const updateStripeSubscriptionURL = `${accountEndpoint}updateStripeSubscription/`;
export const getSubscriptionsURL = `${billingEndpoint}subscriptions/`;
export const cancelSubscriptionURL = `${accountEndpoint}cancelSubscription/`;
export const resubscribeURL = `${accountEndpoint}resubscribe/`;
export const deleteAccountURL = `${accountEndpoint}deleteAccount`;
export const getCardUpdateSecretURL = `${accountEndpoint}getCardUpdateSecret/`;
export const setPaymentIDURL = `${accountEndpoint}setPaymentID/`;
export const pricesURL = `${billingEndpoint}prices/`;

export const stockHistURL = `${stockEndpoint}eod/`;
export const newRowURL = `${stockEndpoint}newRow/`;
export const stockProfileURL = `${stockEndpoint}token/`;
export const verifyURL = `${stockEndpoint}verify/`;
export const tableURL = `${stockEndpoint}table/`;
export const allTickersURL = `${stockEndpoint}allTickers/`;
export const updatedURL = `${stockEndpoint}updated/`;
export const countURL = `${stockEndpoint}count/`;
export const compareURL = `${stockEndpoint}compare/`;
export const searchURL = `${stockEndpoint}search/`;
export const refreshStockURL = `${stockEndpoint}token/refresh/`;
export const testURL = `${stockEndpoint}test/`;
export const hideCodeURL = `${stockEndpoint}token/ignore/`;

export const loginPageURL = `${rootURL}login/`
export const signupPageURL = `${rootURL}signup/`
export const signinPageURL = `${rootURL}signin/`
export const signupVerifiedPageURL = `${signupPageURL}verified/`
export const passwordResetPageURL = `${rootURL}password/reset/`
export const accountPageURL = `${rootURL}account/`
export const settingsPageURL = `${rootURL}settings/`
export const subscribePageURL = `${rootURL}upgrade/`
export const successPageURL = `${rootURL}success/`
export const dividendchartPageURL = `${rootURL}dividend/`
export const momentumchartPageURL = `${rootURL}momentum/`
export const historicalPageURL = `${rootURL}historical/`
export const homePageURL = `${rootURL}`
export const howtoPageURL = `${rootURL}howto/`
export const contactPageURL = `${rootURL}contact/`
export const termsPageURL = `${rootURL}terms/`
export const privacyPageURL = `${rootURL}privacy/`
export const cookiePageURL = `${rootURL}cookies/`
export const disclaimerPageURL = `${rootURL}disclaimer/`
export const sitemapURL = `${rootURL}sitemap.xml`
export const refundPolicyURL = `${rootURL}terms/#refunds`