export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_CREATED = 'AUTH_CREATED';
export const AUTH_VERIFIED = 'AUTH_VERIFIED';
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET';
export const AUTH_PASSWORD_VERIFY = 'AUTH_PASSWORD_VERIFY';
export const AUTH_PASSWORD_VERIFIED = 'AUTH_PASSWORD_VERIFIED';
export const AUTH_PASSWORD_CHANGE = 'AUTH_PASSWORD_CHANGE';
export const AUTH_EMAIL_CHANGE = 'AUTH_EMAIL_CHANGE';
export const AUTH_EMAIL_VERIFY = 'AUTH_EMAIL_VERIFY';

export const PROFILE_START = "PROFILE_START";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";
export const PROFILE_GET_FAVORITES = "PROFILE_GET_FAVORITES";
export const PROFILE_CREATE_FAVORITES = "PROFILE_CREATE_FAVORITES";
export const PROFILE_PATCH_FAVORITES = "PROFILE_PATCH_FAVORITES";
export const PROFILE_CLEAR = "PROFILE_CLEAR";
export const PROFILE_DELETE_FAVORITE = "PROFILE_DELETE_FAVORITE";

export const STOCK_START = "STOCK_START";
export const STOCK_SUCCESS = "STOCK_SUCCESS";
export const STOCK_FAIL = "STOCK_FAIL";

export const CONTACT_START = "CONTACT_START";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";
export const CONTACT_CLEAR = "CONTACT_CLEAR";

export const COUPON_START = "COUPON_START";
export const COUPON_SUCCESS = "COUPON_SUCCESS";
export const COUPON_FAIL = "COUPON_FAIL";
export const COUPON_CLEAR = "COUPON_CLEAR";

export const BILLING_START = "BILLING_START";
export const BILLING_SUCCESS = "BILLING_SUCCESS";
export const BILLING_USER_VERIFIED = "BILLING_USER_VERIFIED";
export const BILLING_SUBSCRIPTIONS = "BILLING_SUBSCRIPTIONS";
export const BILLING_SUB_UPDATE_PREVIEW = "BILLING_SUB_UPDATE_PREVIEW";
export const BILLING_SUB_UPDATE = "BILLING_SUB_UPDATE";
export const BILLING_CARD_UPDATE = "BILLING_CARD_UPDATE";
export const BILLING_FAIL = "BILLING_FAIL";
export const BILLING_CLEAR = "BILLING_CLEAR";

export const CLEAR_ERR = "CLEAR_ERR";