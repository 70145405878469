import {
  BILLING_START,
  BILLING_SUCCESS,
  BILLING_USER_VERIFIED,
  BILLING_SUBSCRIPTIONS,
  BILLING_SUB_UPDATE_PREVIEW,
  BILLING_SUB_UPDATE,
  BILLING_CARD_UPDATE,
  BILLING_FAIL,
  BILLING_CLEAR
} from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  success: false,
  userVerified: null,
  error: null,
  loading: false,
  data: null,
  subscriptions: null,
  cardUpdate: null,
  subUpdatePreview: null,
  subUpdate: null,
};

const BillingStart = (state, action) => {
  return updateObject(state, {
    error: null,
    success: false,
    loading: true,
    data: null,
  });
};

const BillingSuccess = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    data: action.data,
  });
};

const BillingUserVerified = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    userVerified: action.data,
  })
}

const BillingSubscriptions = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subscriptions: action.data,
  })
}

const BillingSubUpdatePreview = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subUpdatePreview: action.data.preview,
  })
}

const BillingSubUpdate = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    subUpdate: action.data,
  })
}

const BillingCardUpdate = (state, action) => {
  return updateObject(state, {
    success: true,
    error: null,
    loading: false,
    cardUpdate: action.data,
  })
}

const BillingFail = (state, action) => {
  return updateObject(state, {
    success: false,
    error: action.error?.response?.data || action.error?.message,
    loading: false,
    data: null,
  });
};

const BillingClear = (state, action) => {
  return updateObject(state, {
    success: false,
    error: null,
    loading: false,
    data: null,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BILLING_START:
      return BillingStart(state, action);
    case BILLING_SUCCESS:
      return BillingSuccess(state, action);
    case BILLING_USER_VERIFIED:
      return BillingUserVerified(state, action);
    case BILLING_SUBSCRIPTIONS:
      return BillingSubscriptions(state, action);
    case BILLING_SUB_UPDATE_PREVIEW:
      return BillingSubUpdatePreview(state, action);
    case BILLING_SUB_UPDATE:
      return BillingSubUpdate(state, action);
    case BILLING_CARD_UPDATE:
      return BillingCardUpdate(state, action);
    case BILLING_FAIL:
      return BillingFail(state, action);
    case BILLING_CLEAR:
      return BillingClear(state, action);
    default:
      return state;
  }
};

export default reducer;
